import { HELPERS } from "../../../application";
import { ALEPH_ZERO } from "../helpers";
import { POLKADOTJS } from "../../polkadotjs";

$(document).ready(function () {
  if ($("#smart-contract-hub-new").length) {
    const SMART_CONTRACTS_NEW = {
      init: async () => {
        SMART_CONTRACTS_NEW.addListeners();
        ALEPH_ZERO.activatePolkadotJsExtension();
        // === INFO LINK ===
        $(".page-title h1 a").removeClass("d-none");
        $(".page-title h1 a").attr(
          "href",
          "https://btn-group.gitbook.io/smart-contract-hub/"
        );
        $(".page-title h1 a").attr("target", "_blank");
        $(".page-title h1 a").attr("rel", "noopener");
      },
      addListeners: () => {
        $(document).on("aleph_zero_account_selected", async () => {
          await ALEPH_ZERO.contracts.azeroIdRouter.getAndSetDomains();
          let $selectBox = $(
            "select[name='active_storage_smart_contract[azero_id]']"
          );
          $selectBox.html("");
          ALEPH_ZERO.contracts.azeroIdRouter.domains.forEach(function (domain) {
            $selectBox.append(
              $("<option>", {
                value: domain,
                text: domain,
                selected:
                  domain == ALEPH_ZERO.contracts.azeroIdRouter.primaryDomain,
              })
            );
          });

          $selectBox = $(
            "select[name='active_storage_smart_contract[group_id]']"
          );
          $selectBox.html("");
          $selectBox.append(
            $("<option>", {
              value: undefined,
              text: "",
            })
          );
          let groupUsers = await ALEPH_ZERO.subsquid.groupUsers();
          groupUsers.forEach(function (groupUser) {
            $selectBox.append(
              $("<option>", {
                value: groupUser.group.id,
                text: groupUser.group.name,
              })
            );
          });
        });

        // === CUSTOM VALIDATIONS ===
        $("#active_storage_smart_contract_smart_contract_address").on(
          "input",
          async (_evt) => {
            let customValidity = "";
            if (
              !POLKADOTJS.validateAddress(
                $("#active_storage_smart_contract_smart_contract_address").val()
              )
            ) {
              customValidity = "Invalid address.";
            }
            $(
              "#active_storage_smart_contract_smart_contract_address"
            )[0].setCustomValidity(customValidity);
          }
        );

        // === DROPZONE ===
        SMART_CONTRACTS_NEW.createDropZones();

        // === FORMS ===
        // pub fn create(
        //     &mut self,
        //     smart_contract_address: AccountId,
        //     chain: u8,
        //     azero_id: String,
        //     abi_url: String,
        //     contract_url: Option<String>,
        //     wasm_url: Option<String>,
        //     audit_url: Option<String>,
        //     group_id: Option<u32>,
        //     project_name: Option<String>,
        //     project_website: Option<String>,
        //     github: Option<String>,
        // ) -> Result<SmartContract> {
        document.smartContractNewForm.onsubmit = async (e) => {
          e.target.classList.add("was-validated");
          e.stopPropagation();
          e.preventDefault();
          if (!e.target.checkValidity()) {
            return;
          }
          document.disableButton(e.submitter);
          try {
            let address =
              document.smartContractNewForm[
                "active_storage_smart_contract[smart_contract_address]"
              ].value;
            let chain =
              document.smartContractNewForm[
                "active_storage_smart_contract[chain]"
              ].value;
            let azeroId =
              document.smartContractNewForm[
                "active_storage_smart_contract[azero_id]"
              ].value;
            let abiUrl =
              document.smartContractNewForm[
                "active_storage_smart_contract[abi_url]"
              ].value;
            let contractUrl =
              document.smartContractNewForm[
                "active_storage_smart_contract[contract_url]"
              ].value || undefined;
            let wasmUrl =
              document.smartContractNewForm[
                "active_storage_smart_contract[wasm_url]"
              ].value || undefined;
            let auditUrl =
              document.smartContractNewForm[
                "active_storage_smart_contract[audit_url]"
              ].value || undefined;
            let groupId =
              document.smartContractNewForm[
                "active_storage_smart_contract[group_id]"
              ].value || undefined;
            let projectName =
              document.smartContractNewForm[
                "active_storage_smart_contract[project_name]"
              ].value || undefined;
            let projectWebsite =
              document.smartContractNewForm[
                "active_storage_smart_contract[project_website]"
              ].value || undefined;
            let github =
              document.smartContractNewForm[
                "active_storage_smart_contract[github]"
              ].value || undefined;

            let api = await ALEPH_ZERO.api();
            let account = ALEPH_ZERO.account;
            api.setSigner(POLKADOTJS.adapter.signer);
            const contract = await ALEPH_ZERO.contracts[
              "smartContractHub"
            ].getContract();
            let response = await POLKADOTJS.contractTx(
              api,
              account.address,
              contract,
              "create",
              undefined,
              [
                address,
                chain,
                azeroId,
                abiUrl,
                contractUrl,
                wasmUrl,
                auditUrl,
                groupId,
                projectName,
                projectWebsite,
                github,
              ]
            );
            await ALEPH_ZERO.subsquid.waitForSync(response);
            window.location.replace(
              `/aleph_zero/smart_contract_hub/?search=${
                JSON.parse(response.decodedOutput).Ok.id
              }&search_by=id`
            );
          } catch (err) {
            document.showAlertDanger(err);
          } finally {
            document.enableButton(e.submitter);
          }
        };
      },
      createDropZones: function () {
        let csrfToken = $(
          "form#new_active_storage_smart_contract input[name=authenticity_token]"
        ).val();
        let directUploadUrl = $("#active_storage_smart_contract_abi").attr(
          "data-direct-upload-url"
        );
        HELPERS.dropzone.create(
          "#abi-dropzone",
          "application/json",
          "#active_storage_smart_contract_abi_url",
          0.5,
          csrfToken,
          directUploadUrl
        );
        HELPERS.dropzone.create(
          "#contract-dropzone",
          ".contract",
          "#active_storage_smart_contract_contract_url",
          0.5,
          csrfToken,
          directUploadUrl
        );
        HELPERS.dropzone.create(
          "#wasm-dropzone",
          "application/wasm",
          "#active_storage_smart_contract_wasm_url",
          0.5,
          csrfToken,
          directUploadUrl
        );
        HELPERS.dropzone.create(
          "#audit-dropzone",
          "application/pdf",
          "#active_storage_smart_contract_audit_url",
          2.5,
          csrfToken,
          directUploadUrl
        );
      },
    };
    SMART_CONTRACTS_NEW.init();
  }
});
