$(function () {
  if ($("#trading").length) {
    const TRADING = {
      assets: undefined,
      assetsTable: $("#assets-table").DataTable({
        columns: [
          {
            data: "currency",
          },
          {
            data: "type",
          },
          {
            data: "balance",
          },
          {
            data: "available",
          },
          {
            data: "holds",
          },
          {
            data: "usd_value",
            title: "$",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(document.amountToCurrency(sData, 1));
            },
          },
        ],
        dom: '<"top"i>frtp',
        order: [[0, "asc"]],
        ordering: true,
        paging: false,
        createdRow: function (row, _data) {
          $(row).addClass("align-baseline");
        },
        drawCallback: function () {
          $("#assets-table").removeClass("gy-5");
        },
      }),
      isolatedTable: $("#isolated-table").DataTable({
        columns: [
          {
            data: "symbol",
          },
          {
            data: "debtRatio",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              if (Number(sData) >= 0.87) {
                $("[href='#tab-isolated']").addClass("bg-danger");
              }
            },
          },
          {
            data: "baseAsset.currency",
            title: "Base",
          },
          {
            data: "baseAsset.liability",
            title: "Liability",
          },
          {
            data: "baseAsset.total",
            title: "Total",
          },
          {
            data: "baseAsset.available",
            title: "Available",
          },
          {
            data: "baseAsset.hold",
            title: "Hold",
          },
          {
            data: "baseAsset.maxBorrowSize",
            title: "Borrowable",
          },
          {
            data: "quoteAsset.currency",
            title: "Quote",
          },
          {
            data: "quoteAsset.liability",
            title: "Liability",
          },
          {
            data: "quoteAsset.total",
            title: "Total",
          },
          {
            data: "quoteAsset.available",
            title: "Available",
          },
          {
            data: "quoteAsset.hold",
            title: "Hold",
          },
          {
            data: "quoteAsset.maxBorrowSize",
            title: "Borrowable",
          },
        ],
        dom: '<"top"i>frtp',
        order: [[0, "asc"]],
        ordering: true,
        paging: false,
        createdRow: function (row, _data) {
          $(row).addClass("align-baseline");
        },
        drawCallback: function () {
          $("#isolated-table").removeClass("gy-5");
        },
      }),
      iptacrTable: $("#iptacr-table").DataTable({
        columns: [
          {
            data: "datetime",
            title: "Datetime",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              let date = new Date(sData);
              let dateFormatted = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")} ${date
                .getHours()
                .toString()
                .padStart(2, "0")}:${date
                .getMinutes()
                .toString()
                .padStart(2, "0")}`;
              $(nTd).html(dateFormatted);
            },
          },
          {
            data: "interval_pool_trading_alert_condition.interval_pool.pool.third_party_identifier",
            title: "Symbol",
          },
          {
            data: "interval_pool_trading_alert_condition.interval_pool.interval",
            title: "Interval",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(`${sData.number_of_units} ${sData.unit}`);
            },
          },
          {
            data: "category",
            title: "Category",
          },
          {
            data: "value",
            title: "Value",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let textClassName = "text-success";
              if (
                oData.interval_pool_trading_alert_condition
                  .trading_alert_condition.action == "red_dot_sm"
              ) {
                textClassName = "text-danger";
              }
              $(nTd).html(
                `<div class="${textClassName}">${Math.round(sData)}</div>`
              );
            },
          },
          {
            data: "money_flow",
            title: "Money Flow",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              let textClassName = "";
              if (sData < 0) {
                textClassName = "text-danger";
              } else if (sData > 0) {
                textClassName = "text-success";
              }
              $(nTd).html(
                `<div class="${textClassName}">${Math.round(sData)}</div>`
              );
            },
          },
          {
            data: "tracking",
            title: "Tracking",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let iconClass = "bi-x-lg";
              let btnColorClass = "btn-secondary";
              if (sData) {
                iconClass = "bi-check-lg";
                btnColorClass = "btn-success";
              }
              $(nTd).html(
                `<a href="#" class="btn btn-sm btn-icon ${btnColorClass} toggling-button" data-iptrac-id="${oData.id}" data-iptrac-toggling-param="tracking" data-iptrac-toggling-value="${sData}"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></div><div class="ready"><em class="bi ${iconClass}"></em></div></a>`
              );
            },
          },
          {
            data: "checked",
            title: "Checked",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let iconClass = "bi-x-lg";
              let btnColorClass = "btn-secondary";
              if (sData) {
                iconClass = "bi-check-lg";
                btnColorClass = "btn-success";
              }
              $(nTd).html(
                `<a href="#" class="btn btn-sm btn-icon ${btnColorClass} toggling-button" data-iptrac-id="${oData.id}" data-iptrac-toggling-param="checked" data-iptrac-toggling-value="${sData}"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></div><div class="ready"><em class="bi ${iconClass}"></em></div></a>`
              );
            },
          },
          {
            data: "id",
            title: "",
            searchable: false,
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(
                `<div class="d-flex"><a href="/admin/interval_pool_trading_alert_condition_records/${sData}/edit" target="_blank" class="btn btn-sm btn-icon btn-secondary"><em class="bi bi-pencil-square"></em></a></div>`
              );
            },
          },
        ],
        dom: '<"top"i>frtp',
        order: [[0, "desc"]],
        ordering: true,
        paging: false,
        createdRow: function (row, _data) {
          $(row).addClass("align-baseline");
        },
        drawCallback: function () {
          $("#iptacr-table").removeClass("gy-5");
          $(".toggling-button").on("click", async (event) => {
            event.preventDefault();
            try {
              document.disableButton(event.currentTarget);
              let togglingParam = $(event.currentTarget).data(
                "iptracTogglingParam"
              );
              let data = {
                interval_pool_trading_alert_condition_record: {},
              };
              let newValue = !$(event.currentTarget).data(
                "iptracTogglingValue"
              );
              if (togglingParam == "tracking") {
                data.interval_pool_trading_alert_condition_record.tracking =
                  newValue;
              } else if (togglingParam == "checked") {
                data.interval_pool_trading_alert_condition_record.checked =
                  newValue;
              }
              let response = await $.ajax({
                url: `/interval_pool_trading_alert_condition_records/${$(
                  event.currentTarget
                ).data("iptracId")}`,
                type: "put",
                data,
                dataType: "json",
              });
              if (togglingParam == "tracking") {
                newValue = response["tracking"];
              } else if (togglingParam == "checked") {
                newValue = response["checked"];
              }
              if (newValue) {
                $(event.currentTarget)
                  .find(".ready .bi")
                  .removeClass("bi-x-lg");
                $(event.currentTarget)
                  .find(".ready .bi")
                  .addClass("bi-check-lg");
                $(event.currentTarget).addClass("btn-success");
                $(event.currentTarget).removeClass("btn-secondary");
              } else {
                $(event.currentTarget).find(".ready .bi").addClass("bi-x-lg");
                $(event.currentTarget)
                  .find(".ready .bi")
                  .removeClass("bi-check-lg");
                $(event.currentTarget).removeClass("btn-success");
                $(event.currentTarget).addClass("btn-secondary");
              }
              $(event.currentTarget).data("iptracTogglingValue", newValue);
            } catch (err) {
              document.showAlertDanger(err);
            } finally {
              document.enableButton(event.currentTarget);
            }
          });
        },
      }),
      updateIntervalPoolTradingAlertConditions: undefined,
      updateAssetsTable: undefined,
      updateIntervaleStatsTable: undefined,
      updateIsolatedTable: undefined,
      updateTable: undefined,
      init: async () => {
        $("#kt_app_content").removeClass("container-xxl");
        $("#play-sound").on("click", function (e) {
          e.preventDefault();
          $("#play-sound").addClass("d-none");
          $("#bird-audio").removeClass("d-none");
        });
        await TRADING.refreshAssetsTable();
        TRADING.refreshAssetsTableInterval();
        await TRADING.refreshIsolatedTable();
        TRADING.refreshIsolatedTableInterval();
        await TRADING.refreshTable();
        TRADING.refreshTableInterval();
        await TRADING.refreshIntervalPoolTradingAlertConditions();
        TRADING.refreshIntervalPoolTradingAlertConditionsInterval();
        await TRADING.refreshIntervalStatsTable();
        TRADING.refreshIntervalStatsTableInterval();
      },
      refreshIntervalPoolTradingAlertConditions: async () => {
        try {
          let response = await $.ajax({
            url: `/interval_pool_trading_alert_condition_records`,
          });
          TRADING.iptacrTable.clear();
          TRADING.iptacrTable.rows.add(response);
          TRADING.iptacrTable.columns.adjust().draw();
          let playSound = false;
          response.forEach(function (iptacr) {
            if (!playSound && !iptacr.checked) {
              playSound = true;
            }
          });
          if (playSound && !$("#bird-audio").hasClass("d-none")) {
            $("#bird-audio audio")[0].play();
          }
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshIsolatedTable: async () => {
        try {
          $("[href='#tab-isolated']").removeClass("bg-danger");
          let response = await $.ajax({
            url: `/credentials/5/funding_isolated`,
          });
          TRADING.isolatedTable.clear();
          TRADING.isolatedTable.rows.add(response.assets);
          TRADING.isolatedTable.columns.adjust().draw();
          let netIsolatedValue =
            Number(response["totalAssetOfQuoteCurrency"]) -
            Number(response["totalLiabilityOfQuoteCurrency"]);
          $("[href='#tab-isolated']").html(
            `Isolated<br>${document.amountToCurrency(netIsolatedValue, 1)}`
          );
          if (TRADING.assets) {
            TRADING.assets.forEach(function (asset) {
              if (asset["type"] == "main" || asset["type"] == "trade") {
                netIsolatedValue += Number(asset["usd_value"]);
              }
            });
          }
          $("[href='#tab-assets']").html(
            `Assets<br>${document.amountToCurrency(netIsolatedValue, 1)}`
          );
          $("head title").text(
            `${document.amountToCurrency(netIsolatedValue, 1)} | trading`
          );
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshIsolatedTableInterval: () => {
        clearInterval(TRADING.updateIsolatedTable);
        TRADING.updateIsolatedTable = setInterval(function () {
          TRADING.refreshIsolatedTable();
        }, 60_000);
      },
      refreshTable: async () => {
        try {
          $.ajax({
            url: `/interval_pool_trading_alert_condition_records/trading_table`,
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshTableInterval: () => {
        clearInterval(TRADING.updateTable);
        TRADING.updateTable = setInterval(function () {
          TRADING.refreshTable();
        }, 60_000);
      },
      refreshAssetsTable: async () => {
        try {
          let response = await $.ajax({
            url: `/credentials/5/assets`,
          });
          TRADING.assetsTable.clear();
          TRADING.assetsTable.rows.add(response);
          TRADING.assetsTable.columns.adjust().draw();
          TRADING.assets = response;
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshAssetsTableInterval: () => {
        clearInterval(TRADING.updateAssetsTable);
        TRADING.updateAssetsTable = setInterval(function () {
          TRADING.refreshAssetsTable();
        }, 60_000);
      },
      refreshIntervalPoolTradingAlertConditionsInterval: () => {
        clearInterval(TRADING.updateIntervalPoolTradingAlertConditions);
        TRADING.updateIntervalPoolTradingAlertConditions = setInterval(
          function () {
            TRADING.refreshIntervalPoolTradingAlertConditions();
          },
          60_000
        );
      },
      refreshIntervalStatsTable: async () => {
        try {
          $.ajax({
            url: `/intervals/stats_table`,
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshIntervalStatsTableInterval: () => {
        clearInterval(TRADING.updateIntervaleStatsTable);
        TRADING.updateIntervaleStatsTable = setInterval(function () {
          TRADING.refreshIntervalStatsTable();
        }, 60 * 60_000);
      },
    };
    TRADING.init();
  }
});
