// CUSTOM
import { ALEPH_ZERO } from "../helpers";
import { POLKADOTJS } from "../../polkadotjs";

$(document).ready(function () {
  if ($("#aleph-zero-groups").length) {
    ALEPH_ZERO.groups = {
      address: "5HMYuwaZt2F9L7VaS89Z8w4EZ2Azu2SoFthaeE6YTHEBD7dg",
      adminGroupUsers: [],
      environment: "production",
      subsquid: {
        url: "https://squid.subsquid.io/smart-contract-hub/graphql",
        height: async () => {
          try {
            let response = await $.ajax({
              type: "post",
              url: ALEPH_ZERO.groups.subsquid.url,
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify({
                query: `query MyQuery {
                  squidStatus {
                    height
                  }
                }`,
              }),
            });
            return response.data.squidStatus.height;
          } catch (err) {
            document.showAlertDanger(err);
          }
        },
        queryData: (search, searchBy) => {
          let query;
          if (search.length) {
            switch (searchBy) {
              case "id":
                query = `query MyQuery {
                  groups(where: {id_eq: "${search}"}) {
                    id
                    name
                    enabled
                    groupUsers {
                      accountId
                      id
                      role
                    }
                  }
                }`;
                break;
              case "name":
                query = `query MyQuery {
                  groups(where: {name_containsInsensitive: "${search}"}) {
                    id
                    name
                    enabled
                    groupUsers {
                      accountId
                      id
                      role
                    }
                  }
                }`;
                break;
              default:
                query = `query MyQuery {
                groups(where: {groupUsers_some: {accountId_eq: "${search}"}}) {
                  id
                  name
                  enabled
                  groupUsers {
                    accountId
                    id
                    role
                  }
                }
              }`;
            }
          } else {
            query = `query MyQuery {
              groups(limit: 100, orderBy: createdAt_DESC) {
                id
                name
                enabled
                groupUsers {
                  accountId
                  id
                  role
                }
              }
            }`;
          }

          return JSON.stringify({
            query,
          });
        },
        waitForSync: async (response) => {
          let attempt = 1;
          let height = response.result.blockNumber.toNumber();
          let syncing = true;
          while (syncing) {
            await document.delay(3_000);
            let squidHeight = await ALEPH_ZERO.groups.subsquid.height();
            if (squidHeight >= height) {
              syncing = false;
            }
            attempt += 1;
            if (attempt == 5) {
              syncing = true;
              document.showAlertInfo("Subsquid is out of sync");
            }
          }
        },
      },
      datatable: undefined,
      groupId: undefined,
      init: async () => {
        if (!ALEPH_ZERO.groups.datatable) {
          await $.getScript(
            "https://cdn.datatables.net/plug-ins/1.13.6/api/processing().js"
          );
          ALEPH_ZERO.groups.datatable = new DataTable("#groups-table", {
            columns: [
              {
                orderable: false,
                data: null,
                defaultContent:
                  '<button type="button" class="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-docs-datatable-subtable="expand_row"><i class="bi bi-plus fs-3 m-0 toggle-off"></i><i class="bi bi-dash fs-3 m-0 toggle-on"></i></button>',
              },
              {
                data: "id",
              },
              {
                data: "name",
              },
              {
                title: "Status",
                data: "enabled",
                fnCreatedCell: function (nTd, sData, _oData, _iRow) {
                  // If super admin, show edit buton
                  let html;
                  if (sData) {
                    html =
                      '<span class="badge py-3 px-4 fs-7 badge-light-success">Enabled</span>';
                  } else {
                    html =
                      '<span class="badge py-3 px-4 fs-7 badge-light-danger">Disabled</span>';
                  }
                  $(nTd).html(html);
                },
              },
              {
                searchable: false,
                className: "text-end",
                defaultContent: "",
                fnCreatedCell: function (nTd, _sData, oData, _iRow) {
                  // If super admin, show edit buton
                  if (ALEPH_ZERO.account && ALEPH_ZERO.account.address) {
                    let html =
                      '<div class="d-flex justify-content-end flex-shrink-0">';
                    let member = false;
                    oData.groupUsers.forEach(function (groupUser) {
                      if (ALEPH_ZERO.account.address == groupUser.accountId) {
                        member = true;
                        if (groupUser.role == "SuperAdmin") {
                          html += `<a href="#" data-group-id=${oData.id} class="edit-group-link btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm"><i class="bi bi-pencil-square fs-3"></i></a>`;
                        }
                      }
                    });
                    if (!member) {
                      html += `<a href="#" data-group-id=${oData.id} class="join-group-link btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm ms-3"><span class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></span><span class="ready"><i class="bi bi-person-plus fs-3"></i></span></a>`;
                    }
                    html += "</div>";
                    $(nTd).html(html);
                  }
                },
              },
            ],
            ordering: false,
            paging: false,
            processing: true,
            bInfo: false,
            drawCallback: function () {
              $("#groups-table .edit-group-link").on(
                "click",
                async function (e) {
                  e.preventDefault();
                  ALEPH_ZERO.groups.groupId =
                    e.currentTarget.getAttribute("data-group-id");
                  $("#groups-modal").modal("show");
                  $("#group-users-edit-form-container").addClass("d-none");
                  $("#groups-new-form-container").addClass("d-none");
                  $("#groups-edit-form-container").removeClass("d-none");
                  const contract = await ALEPH_ZERO.contracts.getContract(
                    ALEPH_ZERO.groups.address
                  );
                  let api = await ALEPH_ZERO.api(ALEPH_ZERO.groups.environment);
                  let response = await POLKADOTJS.contractQuery(
                    api,
                    ALEPH_ZERO.b3,
                    contract,
                    "groupsShow",
                    undefined,
                    [Number(e.currentTarget.getAttribute("data-group-id"))]
                  );
                  ALEPH_ZERO.processResponse(
                    response,
                    ALEPH_ZERO.groups.address
                  );
                  response = response.output.asOk.asOk.toHuman();
                  document.azGroupsUpdateForm.id.value = response.id;
                  document.azGroupsUpdateForm.name.value = response.name;
                  document.azGroupsUpdateForm.enabled.checked =
                    response.enabled;
                }
              );

              $("#groups-table .join-group-link").on(
                "click",
                async function (e) {
                  let selector = e.currentTarget;
                  let groupId = selector.getAttribute("data-group-id");
                  // Disable button
                  try {
                    document.disableButton(selector);
                    let api = await ALEPH_ZERO.api(
                      ALEPH_ZERO.groups.environment
                    );
                    let account = ALEPH_ZERO.account;
                    api.setSigner(POLKADOTJS.adapter.signer);
                    const contract = await ALEPH_ZERO.contracts.getContract(
                      ALEPH_ZERO.groups.address
                    );
                    let response = await POLKADOTJS.contractTx(
                      api,
                      account.address,
                      contract,
                      "groupUsersCreate",
                      undefined,
                      [Number(groupId)]
                    );
                    await ALEPH_ZERO.groups.subsquid.waitForSync(response);
                    document.showAlertSuccess(`Group joined`, true);
                    $("#search-input").val(groupId);
                    $("#search-by-select").val("id");
                    $("#search-input").trigger("input");
                  } catch (err) {
                    document.showAlertDanger(err);
                  } finally {
                    document.enableButton(selector);
                  }
                }
              );
            },
          });
        }
        ALEPH_ZERO.groups.addListeners();
        // === INFO LINK ===
        $(".page-title h1 a").removeClass("d-none");
        $(".page-title h1 a").attr(
          "href",
          "https://btn-group.gitbook.io/groups/"
        );
        $(".page-title h1 a").attr("target", "_blank");
        $(".page-title h1 a").attr("rel", "noopener");
        // Set contract
        ALEPH_ZERO.contracts.setContract(
          ALEPH_ZERO.groups.address,
          "https://res.cloudinary.com/hv5cxagki/raw/upload/v1695880748/abis/aleph_zero/az_groups_p1reza.json",
          ALEPH_ZERO.groups.environment
        );
        ALEPH_ZERO.activatePolkadotJsExtension();
      },
      queryCount: 0,
      addListeners: () => {
        $(document).on("aleph_zero_account_selected", async () => {
          await ALEPH_ZERO.groups.getAndSetUserAdminGroupUsers();
          $("#search-input").trigger("input");
        });

        $("#search-by-select").on("change", async () => {
          $("#search-input").trigger("input");
        });

        // === INDEX CONTAINER ===
        // Search input
        $("#search-input").on("input", async () => {
          // 1. Set to loading and clear table
          ALEPH_ZERO.groups.datatable.clear();
          ALEPH_ZERO.groups.datatable.processing(true);

          // 2. Increase query count
          ALEPH_ZERO.groups.queryCount += 1;
          let currentQueryCount = ALEPH_ZERO.groups.queryCount;

          // 3. Search
          let search = $("#search-input").val();
          let searchBy = $("#search-by-select").val();
          let groups = [];
          try {
            if (
              (search.length &&
                ((searchBy == "wallet" && POLKADOTJS.validateAddress(search)) ||
                  searchBy != "wallet")) ||
              search.length == 0
            ) {
              $("#aleph-zero-groups .invalid-feedback").removeClass("d-block");
              // 3. Delay
              await document.delay(500);
              if (currentQueryCount == ALEPH_ZERO.groups.queryCount) {
                groups = await $.ajax({
                  type: "post",
                  // url: "https://squid.subsquid.io/smart-contract-hub/graphql",
                  url: ALEPH_ZERO.groups.subsquid.url,
                  contentType: "application/json; charset=utf-8",
                  data: ALEPH_ZERO.groups.subsquid.queryData(search, searchBy),
                });
                if (currentQueryCount == ALEPH_ZERO.groups.queryCount) {
                  groups = groups.data.groups;
                }
              }
            } else if (searchBy == "wallet") {
              $("#aleph-zero-groups .invalid-feedback").addClass("d-block");
            }
          } finally {
            if (currentQueryCount == ALEPH_ZERO.groups.queryCount) {
              ALEPH_ZERO.groups.datatable.processing(false);
              ALEPH_ZERO.groups.datatable.rows.add(groups);
              ALEPH_ZERO.groups.datatable.columns.adjust().draw();
            }
          }
        });

        // Switch to create modal
        $("#groups-new-button").on("click", function () {
          $("#groups-modal").modal("show");
          $("#group-users-edit-form-container").addClass("d-none");
          $("#groups-new-form-container").removeClass("d-none");
          $("#groups-edit-form-container").addClass("d-none");
        });

        // === CREATE FORM CONTAINER ===
        // Create form cancel to index
        $("#groups-new-form-container .btn.btn-secondary").on(
          "click",
          function () {
            $("#groups-modal").modal("hide");
          }
        );

        document.azGroupsCreateForm.onsubmit = async (e) => {
          e.preventDefault();
          document.disableButton(e.submitter);
          try {
            let api = await ALEPH_ZERO.api(ALEPH_ZERO.groups.environment);
            let account = ALEPH_ZERO.account;
            api.setSigner(POLKADOTJS.adapter.signer);
            let name = document.azGroupsCreateForm.name.value;
            const contract = await ALEPH_ZERO.contracts.getContract(
              ALEPH_ZERO.groups.address
            );
            let response = await POLKADOTJS.contractTx(
              api,
              account.address,
              contract,
              "groupsCreate",
              undefined,
              [name]
            );
            await ALEPH_ZERO.groups.subsquid.waitForSync(response);
            $("#groups-modal").modal("hide");
            document.showAlertSuccess(`Group ${name} created`, true);
            document.azGroupsCreateForm.name.value = "";
            $("#search-input").val(JSON.parse(response.decodedOutput).Ok.id);
            $("#search-by-select").val("id");
            $("#search-input").trigger("input");
          } catch (err) {
            document.showAlertDanger(err);
          } finally {
            document.enableButton(e.submitter);
          }
        };

        document.groupUserEditForm.onsubmit = async (e) => {
          e.preventDefault();
          document.disableButton(e.submitter);
          try {
            let api = await ALEPH_ZERO.api(ALEPH_ZERO.groups.environment);
            let account = ALEPH_ZERO.account;
            api.setSigner(POLKADOTJS.adapter.signer);
            let groupId = Number(document.groupUserEditForm.groupId.value);
            let address = document.groupUserEditForm.address.value;
            let role = Number(document.groupUserEditForm.role.value);
            const contract = await ALEPH_ZERO.contracts.getContract(
              ALEPH_ZERO.groups.address
            );
            let response = await POLKADOTJS.contractTx(
              api,
              account.address,
              contract,
              "groupUsersUpdate",
              undefined,
              [groupId, address, role]
            );
            await ALEPH_ZERO.groups.subsquid.waitForSync(response);
            $("#groups-modal").modal("hide");
            document.groupUserEditForm.groupId.value = "";
            document.groupUserEditForm.name.value = "";
            document.groupUserEditForm.address.value = "";
            document.showAlertSuccess("Success", true);
            $("#search-input").val(groupId);
            $("#search-by-select").val("id");
            $("#search-input").trigger("input");
          } catch (err) {
            document.showAlertDanger(err);
          } finally {
            document.enableButton(e.submitter);
          }
        };

        // === EDIT FORM CONTAINER ===
        // Edit form cancel to index
        $("#groups-edit-form-container .btn.btn-secondary").on(
          "click",
          function () {
            $("#groups-modal").modal("hide");
          }
        );

        document.azGroupsUpdateForm.onsubmit = async (e) => {
          e.preventDefault();
          document.disableButton(e.submitter);
          try {
            let api = await ALEPH_ZERO.api(ALEPH_ZERO.groups.environment);
            let account = ALEPH_ZERO.account;
            api.setSigner(POLKADOTJS.adapter.signer);
            let id = document.azGroupsUpdateForm.id.value;
            let name = document.azGroupsUpdateForm.name.value;
            let enabled = document.azGroupsUpdateForm.enabled.checked;
            const contract = await ALEPH_ZERO.contracts.getContract(
              ALEPH_ZERO.groups.address
            );
            let response = await POLKADOTJS.contractTx(
              api,
              account.address,
              contract,
              "groupsUpdate",
              undefined,
              [id, name, enabled]
            );
            await ALEPH_ZERO.groups.subsquid.waitForSync(response);
            $("#groups-modal").modal("hide");
            document.showAlertSuccess(`Group updated`, true);
            document.azGroupsUpdateForm.id.value = "";
            document.azGroupsUpdateForm.name.value = "";
            document.azGroupsUpdateForm.enabled.checked = false;
            $("#search-input").val(JSON.parse(response.decodedOutput).Ok.id);
            $("#search-by-select").val("id");
            $("#search-input").trigger("input");
          } catch (err) {
            document.showAlertDanger(err);
          } finally {
            document.enableButton(e.submitter);
          }
        };

        // Subtable
        ALEPH_ZERO.groups.datatable.on(
          "click",
          'td button[data-kt-docs-datatable-subtable="expand_row"]',
          function (e) {
            let button = e.target.closest("button");
            let tr = e.target.closest("tr");
            let row = ALEPH_ZERO.groups.datatable.row(tr);
            if (row.child.isShown()) {
              // This row is already open - close it
              row.child.hide();
              button.classList.remove("active");
            } else {
              // Open this row
              row
                .child(ALEPH_ZERO.groups.datatableChildRowFormat(row.data()))
                .show();
              button.classList.add("active");
              $("#groups-table .edit-group-user-link").on(
                "click",
                function (e) {
                  let dataset = e.currentTarget.dataset;
                  $("form[name='groupUserEditForm'] input[name='groupId']").val(
                    dataset.groupId
                  );
                  $("form[name='groupUserEditForm'] input[name='name']").val(
                    dataset.groupName
                  );
                  $("form[name='groupUserEditForm'] input[name='address']").val(
                    dataset.accountId
                  );
                  let roleAsInteger;
                  switch (dataset.groupRole) {
                    case "Banned":
                      roleAsInteger = 0;
                      break;
                    case "Applicant":
                      roleAsInteger = 1;
                      break;
                    case "Member":
                      roleAsInteger = 2;
                      break;
                    case "Admin":
                      roleAsInteger = 3;
                      break;
                    default:
                      roleAsInteger = 4;
                  }
                  $("form[name='groupUserEditForm'] select[name='role']")
                    .val(roleAsInteger)
                    .trigger("change");
                  // Enable or disable super admin option
                  for (const adminGroupUser of ALEPH_ZERO.groups
                    .adminGroupUsers) {
                    if (
                      Number(adminGroupUser.id.split("-")[0]) ==
                      Number(dataset.groupId)
                    ) {
                      $(
                        "form[name='groupUserEditForm'] option[value='4']"
                      ).prop("disabled", adminGroupUser.role == "Admin");
                    }
                  }

                  $("#group-users-edit-form-container").removeClass("d-none");
                  $("#groups-new-form-container").addClass("d-none");
                  $("#groups-edit-form-container").addClass("d-none");
                  $("#groups-modal").modal("show");
                }
              );

              $("#groups-table .destroy-group-user-link").on(
                "click",
                async function (e) {
                  let selector = e.currentTarget;
                  let groupId = Number(selector.getAttribute("data-group-id"));
                  let accountId = selector.getAttribute("data-account-id");
                  // Disable button
                  try {
                    document.disableButton(selector);
                    let api = await ALEPH_ZERO.api(
                      ALEPH_ZERO.groups.environment
                    );
                    let account = ALEPH_ZERO.account;
                    api.setSigner(POLKADOTJS.adapter.signer);
                    const contract = await ALEPH_ZERO.contracts.getContract(
                      ALEPH_ZERO.groups.address
                    );
                    let response = await POLKADOTJS.contractTx(
                      api,
                      account.address,
                      contract,
                      "groupUsersDestroy",
                      undefined,
                      [groupId, accountId]
                    );
                    await ALEPH_ZERO.groups.subsquid.waitForSync(response);
                    document.showAlertSuccess("Success", true);
                    $("#search-input").val(groupId);
                    $("#search-by-select").val("id");
                    $("#search-input").trigger("input");
                  } catch (err) {
                    document.showAlertDanger(err);
                  } finally {
                    document.enableButton(selector);
                  }
                }
              );
            }
          }
        );

        // === EDIT GROUP USER FORM CONTAINER ===
        $("#group-users-edit-form-container .btn.btn-secondary").on(
          "click",
          function () {
            $("#groups-modal").modal("hide");
          }
        );
      },
      // Handle when trying to delete other admin
      canDeleteGroupUser: (groupUser) => {
        let groupId = Number(groupUser.id.split("-")[0]);
        if (ALEPH_ZERO.account) {
          if (ALEPH_ZERO.account.address != groupUser.accountId) {
            for (const adminGroupUser of ALEPH_ZERO.groups.adminGroupUsers) {
              if (Number(adminGroupUser.id.split("-")[0]) == groupId) {
                if (groupUser.role == "SuperAdmin") {
                  return adminGroupUser.role == "SuperAdmin";
                } else {
                  return true;
                }
              }
            }
          } else {
            return ["Applicant", "Member", "Admin"].includes(groupUser.role);
          }
        }
        return false;
      },
      canChangeRole: (groupUser) => {
        let groupId = Number(groupUser.id.split("-")[0]);
        if (ALEPH_ZERO.account) {
          if (groupUser.accountId != ALEPH_ZERO.account.address) {
            for (const adminGroupUser of ALEPH_ZERO.groups.adminGroupUsers) {
              if (Number(adminGroupUser.id.split("-")[0]) == groupId) {
                if (groupUser.role == "SuperAdmin") {
                  return adminGroupUser.role == "SuperAdmin";
                } else {
                  return true;
                }
              }
            }
          }
        }
        return false;
      },
      datatableChildRowFormat: (d) => {
        let html = "<table class='table fs-6 align-middle mb-0'>";
        d.groupUsers.forEach(function (groupUser) {
          let actionsHtml = "";
          if (ALEPH_ZERO.groups.canChangeRole(groupUser)) {
            actionsHtml += `<a href="#" data-group-id=${
              groupUser.id.split("-")[0]
            } data-account-id=${groupUser.accountId} data-group-name=${
              d.name
            } data-group-role=${
              groupUser.role
            } class="edit-group-user-link btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm"><i class="bi bi-pencil-square fs-3"></i></a>`;
          }
          if (ALEPH_ZERO.groups.canDeleteGroupUser(groupUser)) {
            actionsHtml += `<a href="#" data-group-id=${
              groupUser.id.split("-")[0]
            } data-account-id=${
              groupUser.id.split("-")[1]
            } class="destroy-group-user-link btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm ms-3"><span class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></span><span class="ready"><i class="bi bi-person-dash fs-3"></i></span></a>`;
          }
          html += `<tr><td><a class="link-primary" href="https://alephzero.subscan.io/account/${groupUser.accountId}" target="_blank">${groupUser.accountId}</a></td><td>${groupUser.role}</td><td class="text-end w-50px"><div class="d-flex justify-content-end flex-shrink-0">${actionsHtml}</div></td></tr>`;
        });
        html += "</table>";
        return html;
      },
      getAndSetUserAdminGroupUsers: async () => {
        try {
          if (ALEPH_ZERO.account && ALEPH_ZERO.account.address) {
            let address = ALEPH_ZERO.account.address;
            let response = await $.ajax({
              type: "post",
              // url: "https://squid.subsquid.io/smart-contract-hub/graphql",
              url: ALEPH_ZERO.groups.subsquid.url,
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify({
                query: `query MyQuery {
                  groupUsers(where: {accountId_eq: "${address}", AND: {role_contains: "Admin"}}) {
                    accountId
                    id
                    role
                  }
                }
                `,
              }),
            });
            if (ALEPH_ZERO.account && address == ALEPH_ZERO.account.address) {
              ALEPH_ZERO.groups.adminGroupUsers = response.data.groupUsers;
            }
          }
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
    };
    ALEPH_ZERO.groups.init();
  }
});
